import React, { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { Container, Grid, Button, Box, Paper, TextField, Typography, Pagination, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Toast from '../shared/toast';
import { DataGrid } from '@mui/x-data-grid';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from '../shared/formatDate';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const TestsProgress = () => {
    const [clienterror, setClienterror] = useState('');
    const [lstClients, setLstClients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [refresh, setRefresh] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('Kit Activated.');
    const [error, setError] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedTestId, setSelectedTestId] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                const clientsxresults = await apiService.getTestProgress();
                if (Array.isArray(clientsxresults.data)) {
                    const clientlst = clientsxresults.data.map(users => ({
                        id: users.id || 0,
                        testId: users.testId || 0,
                        userid: users.user.id || 0,
                        client: (users.user.firstName || '') + ' ' + (users.user.lastName || ''),
                        partner: users.partner ? users.partner.name : '',
                        location: users.partner && users.partner.partnerAddress && users.partner.partnerAddress.city
                            ? users.partner.partnerAddress.city.name : '',
                        testKitId: users.testKitId || '',
                        testNumber: users.testNumber,
                        activated: formatDate(users.activated, false) || '',
                        labReceived: formatDate(users.labReceived, false) || '',
                        questionnaire: users.questionnaireUser && 
                                       users.questionnaireUser.completionTime ? 
                                       formatDate(users.questionnaireUser.completionTime, false) : '',
                        finished: formatDate(users.finished, false) || '',
                        expectedDropOff: formatDate(users.expectedDropOff, false) || '',
                    }));
                    setLstClients(clientlst);
                    setFilteredRows(clientlst);
                }
            } catch (error) {
                console.error('Error fetching clients.', error);
            }
        }
        fetchData();
    }, [refresh]);

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchText(query);
        const filteredData = lstClients.filter(row =>
          row.client.toLowerCase().includes(query) ||
          row.partner.toLowerCase().includes(query) 
        );
        setFilteredRows(filteredData);
      };

    const handleDownloadReport = async (testId) => {
        try {
            const testResultResponse = await apiService.getTestResultById(testId);
            const testResult = testResultResponse.data;

            if (!testResult || !testResult.fileLocation) {
                console.log("File location not found.");
                return;
            }

            const response = await apiService.downloadTestReport(testId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileName = testResult.fileLocation.split('/').pop();

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleOpenConfirmDialog = (testId) => {
        setSelectedTestId(testId);
        setOpenConfirmDialog(true);
    };

    const handleConfirmForward = async () => {
        setOpenConfirmDialog(false);
        if (selectedTestId) {
            try {
                const response = await apiService.forwardResultFile(selectedTestId);
                if (response.status === 200) {
                    setmsgToast('Report forwarded to client successfully.');
                    setError(false);
                } else {
                    setmsgToast('Failed to forward the report.');
                    setError(true); 
                }
            } catch (error) {
                console.error("Error forwarding report:", error);
                setmsgToast('Error forwarding report.');
                setError(true);
            } finally {
                setOpenToast(true);
            }
        }
    };

    const columns = [
        { field: 'client', headerName: 'Client', flex: 1},
        { field: 'partner', headerName: 'Partner', flex: 1 },
        { field: 'location', headerName: 'Location', flex: 1 },
        { field: 'testKitId', headerName: 'Test Kit', flex: 1 },
        { field: 'testNumber', headerName: '#', flex: 1 },
        { field: 'activated', headerName: 'Activated', flex: 1},
        { field: 'expectedDropOff', headerName: 'DropOff', flex: 1},
        { field: 'labReceived', headerName: 'Lab', flex: 1},
        { field: 'questionnaire', headerName: 'QRE', flex: 1},
        { field: 'finished', headerName: 'Finished', flex: 1},
        {
            field: 'download', headerName: 'Download', renderCell: (params) => (
                <Tooltip title="Click to download Report">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleDownloadReport(params.row.id)}
                        disabled={params.row.finished === '-'}
                    >
                        <FileDownloadOutlinedIcon />
                    </Button>
                </Tooltip>
            )
        },
        {
            field: 'forward', headerName: 'Forward', renderCell: (params) => (
                <Tooltip title="Click to forward Report to client">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenConfirmDialog(params.row.id)}
                        disabled={params.row.finished === '-'}
                    >
                        <ForwardToInboxOutlinedIcon />
                    </Button>
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <Header />
            <div className="bgColor">
                <Box sx={{ display: 'flex' }}>
                    <SideBar />
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3 }}
                    >
                    <NavInfo
                        currentLinkText="Test Progress"
                        title="Test Progress"
                        canShowButton={false}
                    />
                        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                        <Paper sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search for TestKits"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                            endAdornment: (
                                                <Button onClick={() => {
                                                    setSearchText('');
                                                    setSearchQuery('');
                                                }}>
                                                    <ClearIcon />
                                                </Button>
                                            ),
                                        }}
                                        sx={{
                                            '.MuiOutlinedInput-root': {
                                                borderRadius: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '.MuiInputAdornment-root': {
                                                margin: '0 4px',
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 2 }}>
                                <DataGrid
                                    fullWidth
                                    rows={filteredRows}
                                    columns={columns.map((column) => ({
                                        ...column,
                                        flex: 1,
                                        p: 2
                                    }))}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    initialState={{
                                        pagination: {
                                          paginationModel: {
                                            pageSize: 5,
                                          },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableSelectionOnClick
                                    checkboxSelection={false}
                                    components={{
                                        Pagination: () => (
                                          <Pagination
                                            count={Math.ceil(filteredRows.length / 5)}
                                            shape="rounded"
                                            hidePrevButton={filteredRows.length <= 5}
                                            hideNextButton={filteredRows.length <= 5}
                                          />
                                        ),
                                    }}
                                    autoHeight
                                    sx={{
                                        '& .MuiTablePagination-selectLabel': {
                                          display: 'none',
                                        },
                                        '& .MuiTablePagination-displayedRows': {
                                          display: 'none',
                                        },
                                        '& .MuiInputBase-root': {
                                          display: 'none',
                                        },
                                        backgroundColor: "#ffffff",
                                        "& .MuiDataGrid-columnHeaders": {
                                          fontWeight: "bold",
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                          fontWeight: "bold",
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="confirm-dialog-title"
            >
                <DialogTitle id="confirm-dialog-title">Forward report to client?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmForward} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Toast
                open={openToast}
                handleClose={() => setOpenToast(false)}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default TestsProgress;
