//url base conforme a role

const urlHomeBase = {
    Admin: '/dashboard',
    Partner: '/manageclient',
    AuthorizedUser: '/manageclient',
    Lab_Manager: '/labreceipt',
    Client: '/clientdash',
    undefined: '/'
  };
  
  export default urlHomeBase;