// Remove useState from validateEmail function
const validateEmail = (email) => {
    if (!email.trim()) {
        return '* Email is required';
    }
    if (!testEmail(email)) {
        return '* The email provided is not valid';
    } else if (!email) {
        return '* Please enter your email';
    }
    return ''; // Return empty string if validation passes
};

const testEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const validator = { validateEmail };