// ManageUser.js
import React, { useState, useEffect } from 'react';
import {
    TextField, Container, Grid, Button, Stack, Checkbox, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableSortLabel, CssBaseline, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Typography, IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import EditNote from '@mui/icons-material/EditNote';
import Toast from '../shared/toast';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import DialogUser from './DialogUser'; // Import UserForm
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const initialState = {
    firstName: "",
    lastName: "",
    mobile: "",
    role: "",
    active: true,
    programTypeId: "",
    email: ""
};

const ManageUser = () => {
    const [fields, setFields] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [lstUsers, setLstUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [searchQuery, setSearchQuery] = useState('');
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [programTypes, setProgramTypes] = useState([]);
    const [partners, setPartners] = useState([]); // For storing partner data
    const [selectedPartner, setSelectedPartner] = useState(''); // For storing selected partner
    const [showOnlyActive, setShowOnlyActive] = useState(true);


    useEffect(() => {
        async function fetchData() {
            try {
                // Restore states from sessionStorage
                const storedSearchQuery = sessionStorage.getItem('searchQuery');
                if (storedSearchQuery) {
                    setSearchQuery(storedSearchQuery);
                }

                const storedActive = sessionStorage.getItem('showOnlyActive');
                if (storedActive !== null) {
                    setShowOnlyActive(storedActive === 'true');
                }

                const storedPage = sessionStorage.getItem('page');
                if (storedPage) {
                    setPage(Number(storedPage));
                }

                const userdata = await authService.getUserData();

                const users = await apiService.usersAll();

                const programTypesResponse = await apiService.getAllProgramTypes(); // Fetching Program Types
                setProgramTypes(programTypesResponse.data);
                
                if (Array.isArray(users.data)) {
                    const userslst = users.data.map(user => ({
                        id: user.id || 0,
                        email: user.email || '',
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        mobile: user.mobile || '',
                        role: user.role || '',
                        programTypeId: user.programTypeId || '',
                        strrole: 0,
                        active: user.active !== undefined ? user.active : false
                    }));

                    userslst.forEach(async users => {
                        const strrole = await authService.getRoleByNumber(users.role);
                        users.strrole = strrole;
                    });
                    setLstUsers(userslst.filter(user => user.id !== Number(userdata.id))); //remove o próprio user da lista

                    setFilteredUsers(userslst.filter(user => user.id !== Number(userdata.id)));
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        }

        fetchData();
    }, [refresh]);

    useEffect(() => {
        sessionStorage.setItem('searchQuery', searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        sessionStorage.setItem('showOnlyActive', showOnlyActive);
    }, [showOnlyActive]);

    useEffect(() => {
        sessionStorage.setItem('page', page);
    }, [page]);

    const handleShowOnlyActiveChange = (event) => {
        setShowOnlyActive(event.target.checked);
        setPage(0); // Reset to first page on filter change
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(6);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChange = (e, field) => {
        const value = field === 'active' ? e.target.checked : e.target.value;
        setFields(prev => ({
            ...prev,
            [field]: value,
        }));
        setErrors({});
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = fields.firstName ? "" : "First Name is required";
        tempErrors.lastName = fields.lastName ? "" : "Last Name is required";
        tempErrors.email = /.+@.+..+/.test(fields.email) ? "" : "Email is not valid";
        tempErrors.programTypeId = fields.programTypeId ? "" : "Program Type is requerid";
        tempErrors.role = fields.role ? "" : "Role is requerid";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const userData = await authService.getUserData();
        if(userData.role === "Partner"){
            fields.role = "6"; //o partner cadastra somente Usuário  
        }
        
        if (validate()) {
            try {
                if (isEditMode) {
                    await apiService.userinfoupdate(fields);
                    setmsgToast('User updated successfully!');

                } else {
                    // Add new user
                    const newuser = await apiService.useradd(fields);
                    
                    if(userData.role === 'Partner'){
                    const userPartnerData = {
                        UserId: newuser.data.id,
                        PartnerId: userData.selectedPartner
                    };

                    await apiService.addUserToPartner(userPartnerData);
                }
                    setmsgToast('User Added Successfully!');
                }
                setError(false);
                setOpenToast(true);
                setOpen(false);
                setFields(initialState);
                setErrors({});

                // Fetch updated users list
            } catch (err) {
                setError(true);
                setmsgToast(err.response.data.message);
                setOpenToast(true);
            }
            setRefresh(!refresh);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const openAddDialog = () => {
        setErrors({});
        setIsEditMode(false);
        setFields(initialState);
        setOpen(true);
    };

    const openEditDialog = (user) => {
        setErrors({});
        setIsEditMode(true);
        setFields(user);
        setOpen(true);
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchQuery(searchText);
        }
    };

    const filteredClients = lstUsers
        .filter(client => {
            const matchesSearchQuery = client.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                client.lastName.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesPartner = selectedPartner === '' || client.partnerId === selectedPartner;

            const matchesActive = !showOnlyActive || client.active; // Only show active users if the checkbox is checked

            return matchesSearchQuery && matchesPartner && matchesActive;
        });

    const filteredClientsCount = filteredClients.length;

    const sortedUsers = filteredClients.slice().sort((a, b) => {

        const compare = (propA, propB, isNumeric = false) => {
            if (propA !== undefined && propB !== undefined) {
                if (isNumeric) {
                    return order === 'asc' ? propA - propB : propB - propA;
                } else {
                    return order === 'asc' ? String(propA).localeCompare(String(propB)) : String(propB).localeCompare(String(propA));
                }
            }
            return 0;
        };

        if (orderBy === 'First Name') {
            return compare(a.firstName, b.firstName);
        }
        if (orderBy === 'Last Name') {
            return compare(a.lastName, b.lastName);
        }
        if (orderBy === 'Email') {
            return compare(a.email, b.email);
        }
        if (orderBy === 'Mobile') {
            return compare(a.mobile, b.mobile);
        }
        if (orderBy === 'role') {
            return compare(a.role, b.role);
        }
        if (orderBy === 'active') {
            return order === 'asc'
                ? (a.active === b.active ? 0 : a.active ? -1 : 1)
                : (a.active === b.active ? 0 : a.active ? 1 : -1);
        }
        return 0;
    });

    const paginatedUsers = sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (page >= Math.ceil(filteredClientsCount / rowsPerPage) && page > 0) {
        setPage(0);  // Reset to the first page if the page number exceeds the new page count
    }

    return (
        <>
        <Header />
            <div className="bgColor">
                <Box sx={{ display: 'flex' }}>
                    <SideBar />
                    <CssBaseline />
                    <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3 }}>
                    <NavInfo
                        currentLinkText="Manage User"
                        title="Manage User"
                        canShowButton={false}
                    />
                        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                        <Paper sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Search"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                            endAdornment: searchText && (
                                                <Button onClick={() => {
                                                    setSearchText('');
                                                    setSearchQuery('');
                                                }}>
                                                    <ClearIcon />
                                                </Button>
                                            ),
                                        }}
                                        sx={{
                                            '.MuiOutlinedInput-root': {
                                                borderRadius: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '.MuiInputAdornment-root': {
                                                margin: '0 4px',
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{ marginTop: "10px" }}>
                                <Tooltip title="Click to Add a New User">
                                    <IconButton
                                        onClick={openAddDialog}
                                        sx={{
                                        backgroundColor: "rgba(197, 220, 255, 1)",
                                        color: "#000000",
                                        fontWeight: "bold",
                                        borderColor: "rgba(197, 220, 255, 1)",
                                        "&:hover": {
                                            backgroundColor: "rgba(150, 200, 255, 1)"
                                        }
                                        }}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                        <Typography variant="body1">Show Active Only</Typography>
                                        <Checkbox
                                            checked={showOnlyActive}
                                            onChange={handleShowOnlyActiveChange}
                                            color="primary"
                                        />
                                    </Stack>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {['First Name', 'Last Name', 'Email', 'Mobile', 'Role', 'Active'].map((field) => (
                                                        <TableCell key={field} sortDirection={orderBy === field ? order : false} sx={{ fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                                            <TableSortLabel
                                                                active={orderBy === field}
                                                                direction={orderBy === field ? order : 'asc'}
                                                                onClick={(event) => handleRequestSort(event, field)}
                                                            >
                                                                {field.charAt(0).toUpperCase() + field.slice(1)}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>


                                            <TableBody>
                                                {paginatedUsers.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{row.firstName}</TableCell>
                                                        <TableCell>{row.lastName}</TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{row.mobile}</TableCell>
                                                        <TableCell>{row.strrole}</TableCell>
                                                        <TableCell>{row.active ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Click to edit User">
                                                                <Button
                                                                    style={{ width: 30, height: 30, paddingTop: 10 }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => openEditDialog(row)}
                                                                >
                                                                    <Box position="relative" display="inline-block">
                                                                        <EditNote style={{ width: 24, height: 24 }} />
                                                                    </Box>
                                                                </Button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        count={filteredClientsCount}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[]}  // Disable the rows per page options
                                        labelRowsPerPage=""       // Hide the "Rows per page" label
                                        sx={{
                                            '& .MuiTablePagination-selectLabel': {
                                                display: 'none',      // Hide the "Rows per page" text
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                                display: 'none',      // Hide the "6–10 of 22" text
                                            },
                                            '& .MuiInputBase-root': {
                                                display: 'none',      // Hide the rows per page dropdown
                                            },
                                        }}


                                    />
                                
                            </Grid>
                            </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>
            <Dialog open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose(); // Call your close handler only if it's not a backdrop click
                    }
                }}
                disableEscapeKeyDown={true}
                maxWidth="sm" fullWidth>
                <DialogTitle>{isEditMode ? 'Edit User' : 'Add User'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isEditMode ? 'Update the details of the user.' : 'Enter the details of the new user.'}
                    </DialogContentText>
                    <DialogUser fields={fields} programTypes={programTypes} handleChange={handleChange} errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">{isEditMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>

            <Toast open={openToast} handleClose={handleCloseToast} message={msgToast} error={error} />
        </>
    );
};

export default ManageUser;
