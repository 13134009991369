import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Button, TextField, Typography, FormControl, Select, Paper, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, FormControlLabel } from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from "../services/authService";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import Toast from '../shared/toast';
import { title } from 'process';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const QuestionnaireQuestions = () => {
    const { questionnaireId, questionnaireUserId } = useParams(); // Recebendo o questionnaireUserId como parâmetro
    const [questionnaire, setQuestionnaire] = useState(null);
    const [questionnaireUser, setQuestionnaireUser] = useState(null);
    const [answers, setAnswers] = useState({});
    const [answersSpecify, setAnswersSpecify] = useState({}); // Estado para armazenar respostas específicas
    const [savedAnswers, setSavedAnswers] = useState([]); // Variável para armazenar as respostas já salvas
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Estado para o Dialog de confirmação
    const [openTermsDialog, setOpenTermsDialog] = useState(false); // Estado para o Dialog de termos e condições
    const [userName, setUserName] = useState();
    const [PersonalInfoAccepted, setPersonalInfoAccepted] = useState(null);
    const [SampleForAnalisisAccepted, setSampleForAnalisisAccepted] = useState(null);
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    useEffect(() => {
        const fetchQuestionnaire = async () => {
            try {
                const response = await apiService.getQuestionnaireById(Number(questionnaireId));
                setQuestionnaire(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchQuestionnaire();
    }, [questionnaireId]);

    useEffect(() => {
        if (questionnaire) {
            const fetchTermsAccept = async () => {
                try {
                    const response = await apiService.getUserQuestionnaireById(Number(questionnaireUserId));
                    setQuestionnaireUser(response.data);

                    const userdata = await authService.getUserData();
                    setUserName(userdata.firstName + ' ' + userdata.lastName);
                    setPersonalInfoAccepted(response.data.agreementTerms != null);
                    setSampleForAnalisisAccepted(response.data.agreementConsent != null);
                    if (response.data.agreementTerms != null & response.data.agreementConsent != null) {
                        setOpenTermsDialog(false);
                    }
                    else {
                        setOpenTermsDialog(true);
                    };
                } catch (err) {
                    console.log(err);
                }
            };

            fetchTermsAccept();
        }
    }, [questionnaire]);

    useEffect(() => {
        if (PersonalInfoAccepted && SampleForAnalisisAccepted) {
            const fetchSavedAnswers = async () => {
                try {
                    const response = await apiService.getQuestionnaireUserAnswersByQuestionnaireUserId(Number(questionnaireUserId));
                    setSavedAnswers(response.data);
                } catch (err) {
                    console.log(err);
                }
            };

            fetchSavedAnswers();
        }
    }, [PersonalInfoAccepted, SampleForAnalisisAccepted, questionnaireUserId]);


    const handleChange = (questionId, value) => {
        setAnswers((prev) => ({ ...prev, [questionId]: value }));
    };

    const handleSpecifyChange = (questionId, specifyValue) => {
        setAnswersSpecify((prev) => ({ ...prev, [questionId]: specifyValue }));
    };

    const handleSubmit = async () => {
        if (!PersonalInfoAccepted) {
            setOpenTermsDialog(true);
            return;
        }

        try {
            for (const [questionId, value] of Object.entries(answers)) {
                await apiService.addQuestionnaireUserAnswer({
                    QuestionnaireUserId: Number(questionnaireUserId),
                    QuestionId: parseInt(questionId, 10),
                    Value: String(value),
                    ValueSpecify: answersSpecify[questionId] || '' // Inclui o valor de `ValueSpecify` se existir
                });
            }

            await apiService.completeQuestionnaireUser(questionnaireUserId);

            setmsgToast('Questionnaire Complete');
            navigate('/questionnaire');
        } catch (err) {
            console.log(err);
            setmsgToast('Error completing the questionnaire.');
            setError(true);
        } finally {
            setOpenToast(true);
        }
    };

    const getSavedAnswerValue = (questionId) => {
        const savedAnswer = savedAnswers.find((sa) => sa.questionId === questionId);
        return savedAnswer ? savedAnswer.value : '';
    };

    const getSavedAnswerSpecifyValue = (questionId) => {
        const savedAnswer = savedAnswers.find((sa) => sa.questionId === questionId);
        return savedAnswer ? savedAnswer.valueSpecify : '';
    };

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmSubmit = () => {
        handleSubmit();
        handleCloseConfirmDialog();
    };

    const handleAcceptTerms = async () => {
        questionnaireUser.agreementConsent = SampleForAnalisisAccepted;
        questionnaireUser.agreementTerms = PersonalInfoAccepted;
        await apiService.updateQuestionnaireUser({
            questionnaireUser
        });

        if (PersonalInfoAccepted != null & SampleForAnalisisAccepted != null) {
            setPersonalInfoAccepted(true);
            setSampleForAnalisisAccepted(true);
            setOpenTermsDialog(false);
        }
    };

    const handlePersonalInfoCheckboxChange = (event) => {
        if (event.target.checked) {
            setPersonalInfoAccepted(new Date().toISOString());
        }
        else {
            setPersonalInfoAccepted(null);
        };
    };

    const handleSampleForAnalisisCheckboxChange = (event) => {
        if (event.target.checked) {
            setSampleForAnalisisAccepted(new Date().toISOString());
        }
        else {
            setSampleForAnalisisAccepted(null);
        };
    };

    if (!questionnaire) {
        return <Typography variant="h6">Loading...</Typography>;
    }

    return (
        <>
        <Header />
        <div className="bgColor">
            <Box sx={{ display: 'flex' }}>
                <SideBar />
                {PersonalInfoAccepted && SampleForAnalisisAccepted && (
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: '100vh',
                            p: 3,
                        }}
                    >
                        <NavInfo
                        currentLinkText={`${questionnaire.programType.name} Questionnaire`}
                        title={`${questionnaire.programType.name} Questionnaire`}
                        canShowButton={false}
                        />

                        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h4" gutterBottom>
                                
                            </Typography>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h7" sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    Please fill out the following questionnaire:
                                </Typography>

                                <form>
                                    <Grid container spacing={2}>
                                        {questionnaire.questionnaireQuestions?.map((qq) => (
                                            <Grid item xs={12} key={qq.question.id}>
                                                <InputLabel
                                                    sx={{
                                                        marginBottom: "1px",
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        display: "block",
                                                        fontSize: "0.875rem",
                                                        whiteSpace: "normal", // Permite quebra de linha
                                                        wordWrap: "break-word", // Força quebra em palavras longas
                                                        overflow: "visible", // Garante que todo o conteúdo seja exibido
                                                    }}
                                                >
                                                    {qq.question.question}
                                                </InputLabel>
                                                {qq.question.dataType === 'Text' ? (
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={answers[qq.question.id] || getSavedAnswerValue(qq.question.id)}
                                                        onChange={(e) => handleChange(qq.question.id, e.target.value)}
                                                    />
                                                ) : (
                                                    <FormControl fullWidth variant="outlined">
                                                        <Select
                                                            value={answers[qq.question.id] || getSavedAnswerValue(qq.question.id)}
                                                            onChange={(e) => handleChange(qq.question.id, e.target.value)}
                                                            label="Answer"
                                                        >
                                                            {qq.question.answers?.map((answer) => (
                                                                <MenuItem key={answer.id} value={answer.id}>
                                                                    {answer.answer}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                {qq.question.answers?.some(
                                                    (answer) => ((answer.id === answers[qq.question.id])
                                                        || (answer.id === getSavedAnswerValue(qq.question.id))) && answer.isSpecify
                                                ) && (
                                                        <TextField
                                                            sx={{ mt: 1 }}
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="Specify your answer"
                                                            value={answersSpecify[qq.question.id] || getSavedAnswerSpecifyValue(qq.question.id)}
                                                            onChange={(e) => handleSpecifyChange(qq.question.id, e.target.value)}
                                                        />
                                                    )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <div className="navInfo__button" style={{ marginTop: "16px" }}>
                                        <Button
                                        variant="primary"
                                        sx={{
                                            background: "#156B79",
                
                                            "&:hover": {
                                            background: "#156B79",
                                            
                                            },
                                        }}
                                        onClick={handleOpenConfirmDialog}
                                        >
                                        <span>Finish</span>
                                        </Button>
                                    </div>
                                </form>
                            </Paper>
                        </Container>
                    </Box>)}
            </Box>

            {/* Modal de confirmação */}
            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you have finished the questionnaire?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openTermsDialog}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpenTermsDialog(false);
                    }
                }} maxWidth='lg'>

                <DialogContent>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', border: '1px solid #ccc', borderTop: '2px solid #ccc', }}>
                        <Typography variant="h5" sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
                            CONSENT TO PROVIDE PERSONAL INFORMATION TO MYOMAR – QUESTIONNAIRE
                        </Typography>
                        <p>
                            I,
                            <Typography component="span" style={{ fontWeight: 'bolder', fontSize: '20px', color: 'blue' }}>{userName}</Typography>
                            , understand that my practitioner has created a profile for me with Myomar Molecular Inc. (“Myomar”) to facilitate
                            the analysis of my urine and questionnaire responses for certain biomarkers relating to muscle health (the “Analysis”), in order for my practitioner
                            to interpret and share with me the results of the Analysis (the “Muscle Health Score”).
                        </p>
                        <p>
                            By clicking “I agree” below, and by completing and then submitting the attached questionnaire to Myomar, I consent to the collection and use of my
                            personal information, including health information, that I provide as responses to the questionnaire, for the purpose of the Analysis. I agree that
                            I have read and understand Myomar’s Privacy Policy as it relates to Participant Personal Information, and that my personal information will be collected,
                            used, and disclosed in accordance with the Privacy Policy and applicable Canadian privacy laws.
                        </p>
                        <p>
                            I also understand and agree that Myomar is not a healthcare provider and does not offer medical advice, diagnosis, treatment, or other healthcare services,
                            and that my practitioner is solely responsible for any treatment plans.
                        </p>
                        <p>
                            I understand and agree that the attached questionnaire includes questions relating to my health habits and lifestyle, as well as general medical history,
                            and that my responses to the questions will be used to generate a Muscle Health Score that will be shared with my practitioner. I understand that the specific
                            questionnaire responses that I provide to Myomar will not be shared with my practitioner, and that the answers will be de-identified and retained by Myomar,
                            following analysis by Myomar.
                        </p>
                        <p>
                            I understand and agree that the Muscle Health Score generated from my responses will be stored by Myomar along with an internal identification code, not my name
                            or email address.
                        </p>
                        <p>
                            I understand and agree that my practitioner (not Myomar) is responsible for providing me with the materials and instructions relating to the urine sample to be
                            analyzed by Myomar, and that I will have the opportunity to provide my express consent to my practitioner with respect to any such sample before it is collected
                            by my practitioner or sent to Myomar for analysis.
                        </p>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={PersonalInfoAccepted}
                                    onChange={handlePersonalInfoCheckboxChange}
                                    name="acceptTerms"
                                    color="primary"
                                />
                            }
                            label="I consent to provide personal information to MYOMAR"
                        />

                    </Paper>
                    <br />
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', border: '1px solid #ccc', borderTop: '2px solid #ccc', }}>
                        <Typography variant="h5" sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
                            CONSENT TO PROVIDE URINE SAMPLE FOR ANALYSIS BY MYOMAR
                        </Typography>
                        <p>
                            I,
                            <Typography component="span" style={{ fontWeight: 'bolder', fontSize: '20px', color: 'blue' }}>{userName}</Typography>
                            , understand that my practitioner has created a profile for me with Myomar Molecular Inc. (“Myomar”) to facilitate the analysis of my
                            urine and questionnaire responses for certain biomarkers relating to muscle health (the “Analysis”), in order for my practitioner to interpret and share with me the
                            results of the Analysis (the “Muscle Health Score”).
                        </p>
                        <p>
                            By clicking “I agree” below, I consent to the collection and use of my urine sample by Myomar (the “Sample”) for the purpose of the Analysis. I agree that I have
                            read and understand Myomar’s Privacy Policy as it relates to Participant Personal Information, and that my personal information, including the Sample, will be
                            collected, used and disclosed in accordance with the Privacy Policy and applicable Canadian privacy laws. I understand that the Sample will be destroyed securely
                            by Myomar following the Analysis.
                        </p>
                        <p>
                            I also understand and agree that Myomar is not a health care provider and does not offer medical advice, diagnosis, treatment, or other healthcare services,
                            and that my practitioner is solely responsible for any treatment plans.</p>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={SampleForAnalisisAccepted}
                                    onChange={handleSampleForAnalisisCheckboxChange}
                                    name="acceptTerms"
                                    color="primary"
                                />
                            }
                            label="I consent to provide urine sample for analysis by MYOMAR"
                        />
                    </Paper>
                </DialogContent>
                <DialogActions>

                    <Button
                        variant="contained"
                        onClick={handleAcceptTerms} color="primary" disabled={!PersonalInfoAccepted || !SampleForAnalisisAccepted}
                        sx={{
                            width: "150px",
                            backgroundColor: "rgba(197, 220, 255, 1)",
                            color: "#000000",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            borderColor: "rgba(197, 220, 255, 1)"
                        }}
                    >
                        Accept
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => navigate('/questionnaire')}
                        sx={{
                            width: "150px",
                            backgroundColor: "rgba(197, 220, 255, 1)",
                            color: "#000000",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            borderColor: "rgba(197, 220, 255, 1)",
                            marginRight: "30px"
                        }}
                    >
                        Decline
                    </Button>
                </DialogActions>
            </Dialog>

            <Toast open={openToast} handleClose={handleCloseToast} message={msgToast} error={error} />
        </div>
        </>
    );
};

export default QuestionnaireQuestions;
