import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../shared/navBar.js';
import SideBar from '../shared/sideBar.js';
import Container from '@mui/material/Container';
import { TextField, Button, Box, Paper, Grid } from "@mui/material";
import FooterBar from "../shared/footer.js";
import Toast from "../shared/toast.js";
import { apiService } from '../services/apiService.js';
import { authService } from '../services/authService.js';
import { validator } from '../shared/validator.js';
import Header from "../shared/header.js";
import NavInfo from "../shared/NavInfo.js";

const QuickActivate = () => {

  const [userData, setUserData] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [returnto, setReturnto] = useState('/');
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    testkitid: '',
    email: '',
    mobile: ''
  });

  const [errors, setErrors] = useState({
    testkitid: '',
    email: '',
    mobile: ''
  });

  const testKitRef = useRef(null); // Referência para o campo TestKit

  useEffect(() => {
    if (testKitRef.current) {
      testKitRef.current.focus(); // Define o foco no campo TestKit ao carregar
    }
  }, []);

  const handleChange = (e, newValue, field) => {
    const value = field === 'mobile' ? newValue.replace(/[^0-9-]/g, '') : newValue;

    if (field === 'mobile' && value.length > 15) {
      value = value.slice(0, 15);
    }

    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };


  const handleCloseToast = () => {
    setOpenToast(false);
    navigate('');
  };

  const resetForm = () => {
    setFields({
      testkitid: '',
      email: '',
      mobile: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    try {
      const existEmail = await apiService.verifyEmail({ param: String(fields.email.toLowerCase()) });
      if (existEmail.data) {
        setErrors((prev) => ({ ...prev, email: '* The email provided already exists' }));
        return;
      }

      const user = await authService.getUserData();

      const userData = {
        Email: fields.email.toLowerCase(),
        TestKitId: fields.testkitid,
        Mobile: fields.mobile,
        UserIdActivated: user.id,
        UserPartnerId: user.partnerId
      };

      const result = await apiService.addQuickActivate(userData);
      if (result.statusText === 'OK') {
        setmsgToast('Test added successfully.');
        setError(false);
        setOpenToast(true);
        resetForm();
      } else {
        setmsgToast(result.data);
        setError(true);
        setOpenToast(true);
        return;
      }


    } catch (error) {
      setmsgToast(error.request.response);
      setError(true);
      setOpenToast(true);
    }
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {};

    if (!fields.testkitid.trim()) {
      newErrors.testkitid = '* TestKit ID is required';
      isValid = false;
    }
    const emailvalid = validator.validateEmail(fields.email);
    if (emailvalid !== '') {
      newErrors.email = emailvalid;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <>
      <Header />
      <div className="bgColor">

        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: 1, overflow: 'auto', p: 3, height: '100vh' }}>
            <NavInfo
              currentLinkText="Quick Activate"
              title="Quick Activate"
              canShowButton={false}
            />

            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>

              <Grid container spacing={2} alignContent={'center'} >

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Centraliza horizontalmente
                    alignItems: "center", // Centraliza verticalmente
                    mx: "auto", // Centraliza horizontalmente em relação ao container pai
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: 400, // Define uma largura máxima para o formulário
                      width: "100%",
                      p: 3,
                      boxShadow: 3,
                      borderRadius: 2,
                      backgroundColor: "#fff", // Fundo branco para contraste
                    }}
                  >
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div style={{ marginBottom: "20px", width: "200px" }}>
                        <TextField
                          fullWidth
                          label="TestKit"
                          value={fields.testkitid}
                          onChange={(e) => handleChange(e, e.target.value, "testkitid")}
                          error={!!errors.testkitid}
                          helperText={errors.testkitid}
                        />
                      </div>
                      <div style={{ marginBottom: "20px", width: "300px" }}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={fields.email}
                          onChange={(e) => handleChange(e, e.target.value, "email")}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </div>
                      <div style={{ marginBottom: "20px", width: "200px" }}>
                        <TextField
                          fullWidth
                          label="Mobile"
                          placeholder="x-xxx-xxxxxxx"
                          value={fields.mobile}
                          onChange={(e) => handleChange(e, e.target.value, "mobile")}
                          error={!!errors.mobile}
                          helperText={errors.mobile}
                        />
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="contained"
                            sx={{ background: "#156B79", "&:hover": { background: "#135A68" } }}
                            type="submit"
                            fullWidth
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="contained"
                            sx={{ background: "#156B79", "&:hover": { background: "#135A68" } }}
                            type="button"
                            onClick={resetForm}
                            fullWidth
                          >
                            Clean
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>


              </Grid>

            </Container>
          </Box>
        </Box>
      </div>
      <Toast open={openToast} handleClose={handleCloseToast}
        message={msgToast}
        error={error}
      />
    </>
  )
};

export default QuickActivate;
