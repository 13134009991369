import React, { useState, useEffect, useCallback } from 'react';
import SideBar from '../shared/sideBar';
import {
    MenuItem, Select, FormControl, InputLabel,
    Container, Grid, Button, Box, Stack, Checkbox,
    Table, TableBody, TableCell, TableContainer, InputAdornment,
    TableHead, TableRow, Paper, TablePagination, TableSortLabel,
    Dialog, DialogTitle, DialogContent, DialogContentText, TextField,
    DialogActions, Typography, Tooltip, IconButton
} from '@mui/material';
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditNote from '@mui/icons-material/EditNote';
import Toast from '../shared/toast';
import DialogUser from './DialogUser';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import TestKitDialog from './DialogTestActivate'; // Reutilizando o modal de ativação do TestActivate
import dayjs from 'dayjs';
import Header from "../shared/header";
import NavInfo from "../shared/NavInfo";

const defaultTheme = createTheme();

const initialState = {
    id: 0,
    firstName: "",
    lastName: "",
    mobile: "",
    role: 4, // Client Role
    active: true,
    programTypeId: "", // Program Type
    email: "",
    sexAtBirth: null
};

const ManageClient = () => {
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState();
    const [lstClients, setLstClients] = useState([]); // Master list of clients
    const [programTypes, setProgramTypes] = useState([]); // Program types list
    const [open, setOpen] = useState(false); // Dialog open state
    const [fields, setFields] = useState(initialState); // Form fields state
    const [errors, setErrors] = useState({}); // Form validation errors
    const [error, setError] = useState(false); // Toast error state
    const [openToast, setOpenToast] = useState(false); // Toast open state
    const [msgToast, setMsgToast] = useState(''); // Toast message
    const [isEditMode, setIsEditMode] = useState(false); // Edit mode state
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
    const [order, setOrder] = useState('asc'); // Sorting order
    const [orderBy, setOrderBy] = useState('firstName'); // Sorting by column
    const [searchQuery, setSearchQuery] = useState(''); // Client search query
    const [searchPartnerQuery, setSearchPartnerQuery] = useState(''); // Partner search query
    const [showOnlyActive, setShowOnlyActive] = useState(true); // Active clients only filter
    const [refresh, setRefresh] = useState(false); // Refresh state
    const [filteredClients, setFilteredClients] = useState([]); // Filtered client list
    const [partners, setPartners] = useState([]); // List of partners
    const [selectedPartner, setSelectedPartner] = useState('');
    const [clientPartners, setClientPartners] = useState([]);
    const [openActivateModal, setOpenActivateModal] = useState(false); // Estado para o modal de ativação
    const [selectedClient, setSelectedClient] = useState(null); // Cliente selecionado para ativação
    const [testKitFields, setTestKitFields] = useState({
        testKitId: '',
        expectedDropOff: dayjs().add(6, 'day'), // Default para o dia seguinte
    });


    // Fetch data from API
    useEffect(() => {
        async function fetchData() {
            try {

                const userdata = await authService.getUserData();
                setUserRole(userdata.role);

                const storedSearchQuery = sessionStorage.getItem('searchQuery');
                if (storedSearchQuery) setSearchQuery(storedSearchQuery);

                const storedActive = sessionStorage.getItem('showOnlyActive');
                if (storedActive !== null) setShowOnlyActive(storedActive === 'true');

                const storedPage = sessionStorage.getItem('page');
                if (storedPage) setPage(Number(storedPage));

                const userData = await authService.getUserData();
                let clientsResponse;

                if (userData.role == "Admin" || userData.role == "Lab_Manager") {
                    clientsResponse = await apiService.clientsForAdm();
                }
                else {
                    clientsResponse = await apiService.userbypartnerid();
                }

                if (Array.isArray(clientsResponse.data)) {
                    const clientList = clientsResponse.data.map(client => {
                        const user = client.user || {};
                        const partner = client.partner || {};

                        return {
                            id: client.userId || 0,
                            email: user.email || '',
                            firstName: user.firstName || '',
                            lastName: user.lastName || '',
                            mobile: user.mobile || '',
                            role: user.role || 9,
                            active: user.active !== undefined ? user.active : false,
                            programTypeId: user.programTypeId || '',
                            sexAtBirth: user.sexAtBirth || '', // Ensure this field exists
                            partnerId: client.partnerId || '',
                            partner: partner.name || ''
                        };
                    });

                    setLstClients(clientList); // Set master list
                }
                const programTypesResponse = await apiService.getAllProgramTypes();
                setProgramTypes(programTypesResponse.data);
            } catch (error) {
                console.error('Error fetching clients or program types.', error);
            }
        }

        fetchData();
    }, [refresh]);

    useEffect(() => {
        sessionStorage.setItem('searchQuery', searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        sessionStorage.setItem('showOnlyActive', showOnlyActive);
    }, [showOnlyActive]);

    useEffect(() => {
        sessionStorage.setItem('page', page);
    }, [page]);

    useEffect(() => {
        async function fetchPartners() {
            try {
                const response = await apiService.partnerall(); // Fetch partners from the API
                setPartners(response.data); // Set partners list
            } catch (error) {
                console.error('Error fetching partners', error);
            }
        }
        fetchPartners();
    }, []);

    const filterAndSortClients = useCallback(() => {
        let filtered = [...lstClients];

        // Filter by active status
        if (showOnlyActive) {
            filtered = filtered.filter(client => client.active);
        }

        // Filter by client search query
        if (searchQuery.trim() !== '') {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(client =>
                client.firstName.toLowerCase().includes(query) ||
                client.lastName.toLowerCase().includes(query) ||
                client.email.toLowerCase().includes(query)
            );
        }

        // Filter by selected partner
        if (selectedPartner) {
            filtered = filtered.filter(client => client.partner.toLowerCase() === selectedPartner.toLowerCase());
        }

        // Sorting logic
        filtered.sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];
            if (orderBy === 'active') {
                aValue = aValue ? 1 : 0;
                bValue = bValue ? 1 : 0;
            }
            return aValue < bValue ? (order === 'asc' ? -1 : 1) : aValue > bValue ? (order === 'asc' ? 1 : -1) : 0;
        });

        // Paginate the filtered list
        const paginated = filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setFilteredClients(paginated);
    }, [lstClients, showOnlyActive, searchQuery, selectedPartner, order, orderBy, page, rowsPerPage]);


    useEffect(() => {
        filterAndSortClients();
    }, [filterAndSortClients]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handlePartnerChange = (event) => {
        setSelectedPartner(event.target.value);
        setPage(0); // Reset to first page on partner change
    };

    // Clear partner selection
    const handleClearPartnerSelection = () => {
        setSelectedPartner('');
        setPage(0); // Reset to first page
        filterAndSortClients(); // Trigger filtering after clearing partner
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const addNew = async () => {
        const userdata = authService.getUserData();
        if (!selectedPartner){
            setSelectedPartner(userdata.userpartner);
        }
        if (userdata.userpartner) {
            setErrors({});
            setFields(initialState);
            setIsEditMode(false);
            setOpen(true);
        }
        else {
            setMsgToast('Please select a partner to add a new client');
            setError(true);
            setOpenToast(true);
        }
    };

    const openEditDialog = async (client) => {
        setErrors({});  // Limpa os erros

        // Aguarda a resposta da função assíncrona para buscar os parceiros do cliente
        await getClientPartners();

        setIsEditMode(true); // Define o modo de edição
        setFields(client);    // Preenche os campos do formulário com os dados do cliente
        setOpen(true);        // Abre o diálogo de edição
    };

    const getClientPartners = async () => {
        try {
            let partnersResponse;
            if (userRole == "Admin") {
                partnersResponse = await apiService.partnerall();
            }
            else {
                partnersResponse = await apiService.partnerByUserId();
            }
            setClientPartners(partnersResponse.data);
        } catch (error) {
            console.error('Error fetching partners', error);
        }
    };

    const handleChange = (e, field) => {
        const value = field === 'active' ? e.target.checked : e.target.value;
        setFields(prev => ({
            ...prev,
            [field]: value,
        }));
        setErrors({});
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = fields.firstName ? "" : "First Name is required";
        tempErrors.lastName = fields.lastName ? "" : "Last Name is required";
        tempErrors.email = /.+@.+\..+/.test(fields.email) ? "" : "Email is not valid";
        tempErrors.programTypeId = fields.programTypeId ? "" : "Program Type is required";
        tempErrors.sexAtBirth = fields.sexAtBirth ? "" : "Sex At Birth is required";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            try {
                if (isEditMode) {
                    await apiService.userinfoupdate(fields);
                    setMsgToast('Client Updated Successfully!');
                } else {
                    const part = partners.find(partner => partner.name === selectedPartner);
                    const newUser = await apiService.useradd(fields);
                    const userData = await authService.getUserData();
                    const userPartnerData = {
                        UserId: newUser.data.id,
                        PartnerId: part.id
                    };
                    await apiService.addUserToPartner(userPartnerData);
                    setMsgToast('Client Added Successfully!');
                }
                setError(false);
                setOpenToast(true);
                setFields(initialState);
                setErrors({});
                handleClose();
                setRefresh(!refresh);
            } catch (err) {
                const errorMessage = err.response?.data?.message || 'An error occurred. Please check the entered data.';
                setMsgToast(errorMessage);
                setError(true);
                setOpenToast(true);
                console.error(err);
            }
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const handleSearchPartnerChange = (event) => {
        setSearchPartnerQuery(event.target.value);
        setPage(0);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setSearchPartnerQuery('');
        setPage(0);
    };

    const handleShowOnlyActiveChange = (event) => {
        setShowOnlyActive(event.target.checked);
        setPage(0);
    };

    // Função para abrir o modal de ativação
    const handleActivateTestKit = (client) => {
        setSelectedClient(client);
        setTestKitFields({
            testKitId: '',
            firstName: client.firstName,
            lastName: client.lastName,
            expectedDropOff: dayjs().add(6, 'day'),
        });
        setOpenActivateModal(true);
    };


    // Função para fechar o modal de ativação
    const handleCloseActivateModal = () => {
        setOpenActivateModal(false);
        setSelectedClient(null);
    };

    // Função para lidar com a ativação do Test Kit
    const handleActivateTestKitSubmit = async () => {
        if (!testKitFields.testKitId || !testKitFields.expectedDropOff) {
            setMsgToast('Provide kit number and expected dropoff date');
            setError(true);
            setOpenToast(true);
            return;
        }

        try {
            const newTestResult = {
                userId: selectedClient.id, // ID do cliente selecionado
                testKitId: testKitFields.testKitId,
                expectedDropOff: testKitFields.expectedDropOff,
            };
            await apiService.activatetest(newTestResult);
            setMsgToast('Test Kit Activated successfully!');
            setError(false);
            setRefresh(!refresh); // Atualiza a lista de clientes
            setOpenToast(true);
            handleCloseActivateModal();
        } catch (err) {
            const errorMessage = err.response?.data || 'An error occurred during activation.';
            setMsgToast(errorMessage);
            setError(true);
            setOpenToast(true);
        }
    };


    return (
        <>
            <Header />
            <div className="bgColor">
                <Box sx={{ display: "flex" }}>
                    <SideBar />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                            p: 3,
                        }}
                    >
                        {/* <NavBar /> */}
                        <NavInfo
                            currentLinkText="Clients"
                            title="Clients"
                            canShowButton={false}
                        />
                        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h4" sx={{ mb: 2 }}>Client Manager</Typography>
                                <Grid container spacing={2}>
                                    {/* Search Client Field */}
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            label="Search Client"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: searchQuery && (
                                                    <InputAdornment position="end">
                                                        <IconButton color="primary" onClick={handleClearSearch}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    {/* Search Partner Field */}

                                    {(userRole === "Admin") &&
                                        (<Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="partner-select-label">Select Partner</InputLabel>
                                                <Select
                                                    labelId="partner-select-label"
                                                    value={selectedPartner}
                                                    onChange={handlePartnerChange}
                                                    label="Select Partner"
                                                    endAdornment={selectedPartner && (
                                                        <InputAdornment position="end">
                                                            <IconButton color="primary" onClick={handleClearPartnerSelection}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )}
                                                >
                                                    {/* Map over the list of partners to create MenuItems */}
                                                    {partners.map((partner) => (
                                                        <MenuItem key={partner.id} value={partner.name}>
                                                            {partner.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>)}

                                    {/* Add Client Button */}
                                    {(userRole !== "Lab_Manager") &&
                                        (<Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title="Click to Add a New Client">
                                                <IconButton
                                                    onClick={addNew}
                                                    sx={{
                                                        backgroundColor: "rgba(197, 220, 255, 1)",
                                                        color: "#000000",
                                                        fontWeight: "bold",
                                                        borderColor: "rgba(197, 220, 255, 1)",
                                                        "&:hover": {
                                                            backgroundColor: "rgba(150, 200, 255, 1)"
                                                        }
                                                    }}
                                                >
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>)
                                    }

                                    {/* Show Active Only Checkbox */}
                                    <Grid item xs={3}>
                                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                            <Typography variant="body1">Show Active Only</Typography>
                                            <Checkbox
                                                checked={showOnlyActive}
                                                onChange={handleShowOnlyActiveChange}
                                                color="primary"
                                            />
                                        </Stack>
                                    </Grid>

                                    {/* Clients Table */}
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {/* Define table headers with sorting */}
                                                        {[
                                                            { id: 'firstName', label: 'First Name' },
                                                            { id: 'lastName', label: 'Last Name' },
                                                            { id: 'email', label: 'Email' },
                                                            { id: 'mobile', label: 'Mobile' },
                                                            { id: 'partner', label: 'Partner' },
                                                            { id: 'active', label: 'Active' },
                                                            { id: 'actions', label: 'Actions', sortable: false },
                                                            { id: 'activateTestKit', label: 'TestKit', sortable: false } // Nova coluna
                                                        ].map((headCell) => (
                                                            <TableCell
                                                                key={headCell.id}
                                                                sortDirection={orderBy === headCell.id ? order : false}
                                                                sx={{ fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}
                                                            >
                                                                {headCell.sortable !== false ? (
                                                                    <TableSortLabel
                                                                        active={orderBy === headCell.id}
                                                                        direction={orderBy === headCell.id ? order : 'asc'}
                                                                        onClick={(event) => handleRequestSort(event, headCell.id)}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableSortLabel>
                                                                ) : (
                                                                    headCell.label
                                                                )}
                                                            </TableCell>
                                                        ))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredClients.map((client) => (
                                                        <TableRow key={client.id}>
                                                            <TableCell>{client.firstName}</TableCell>
                                                            <TableCell>{client.lastName}</TableCell>
                                                            <TableCell>{client.email}</TableCell>
                                                            <TableCell>{client.mobile}</TableCell>
                                                            <TableCell>{client.partner}</TableCell>
                                                            <TableCell>
                                                                {client.active ? (
                                                                    <CheckIcon style={{ color: 'green' }} />
                                                                ) : (
                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(userRole !== "Lab_Manager") &&
                                                                    <Tooltip title="Edit User">
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => openEditDialog(client)}
                                                                            color="primary"
                                                                        >
                                                                            <EditNote />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </TableCell>
                                                            {/* Botão para ativar o Test Kit */}
                                                            <TableCell>
                                                                <Tooltip title="Activate Test Kit">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleActivateTestKit(client)}
                                                                        color="primary"
                                                                    >
                                                                        <QrCodeScanner />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>

                                                    ))}
                                                    {filteredClients.length === 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={7} align="center">
                                                                No clients found.
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            count={showOnlyActive
                                                ? lstClients.filter(client => client.active).length
                                                : lstClients.length}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[]}  // Disable rows per page options
                                            labelRowsPerPage=""       // Hide "Rows per page" label
                                            sx={{
                                                '& .MuiTablePagination-selectLabel': {
                                                    display: 'none',
                                                },
                                                '& .MuiTablePagination-displayedRows': {
                                                    display: 'none',
                                                },
                                                '& .MuiInputBase-root': {
                                                    display: 'none',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>

            {/* Add/Edit Dialog */}
            <Dialog open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                disableEscapeKeyDown={true}
                maxWidth="sm" fullWidth>
                <DialogTitle>{isEditMode ? 'Edit Client' : 'Add Client'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isEditMode ? 'Update the details of the client.' : 'Enter the details of the new client.'}
                    </DialogContentText>
                    <DialogUser
                        fields={fields}
                        programTypes={programTypes}
                        clientPartners={clientPartners}
                        handleChange={handleChange}
                        errors={errors}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">{isEditMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>

            <TestKitDialog
                open={openActivateModal}
                handleClose={handleCloseActivateModal}
                handleActivate={handleActivateTestKitSubmit}
                fields={testKitFields}
                handleChange={(e, field) => setTestKitFields({ ...testKitFields, [field]: e.target.value })}
                handleDateChange={(date) => setTestKitFields({ ...testKitFields, expectedDropOff: date })}
                errors={errors}
            />

            {/* Toast Notification */}
            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default ManageClient;
