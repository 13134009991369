import React from "react";
import HeaderLogo from "../assets/header-logo.png";

import {
  HiOutlineMenuAlt3,
  HiOutlineUserCircle,
  HiChevronDown,
} from "react-icons/hi";
import { Menu, MenuItem } from "@mui/material";
import { SidebarContext } from "../context/SidebarContext";
import { authService } from "../services/authService";
import { useNavigate } from "react-router-dom";

import "./header.scss";

const Header = () => {
  const { toggleSidebar } = React.useContext(SidebarContext);
  const [userName, setUserName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null); // Estado do menu
  const navigate = useNavigate();

  const fetchData = React.useCallback(async () => {
    const isLoggedIn = await authService.isLoggedIn();
    if (isLoggedIn) {
      const userdata = await authService.getUserData();
      setUserName(userdata.firstName ? `${userdata.firstName} ${userdata.lastName}` : userdata.email);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Define o elemento do menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Fecha o menu
  };

  const userinfo = () => {
    navigate("/userinfo");
    //window.location.reload();
  };

  const changepassword = () => {
    navigate("/resetpassword");
    //window.location.reload();
  };

  const logout = () => {
    authService.logOut();
    navigate("/Home");
    //window.location.reload();
  };

  return (
    <header className="header">
      <div className="header__actions">
        <div className="header__actions__openNav" onClick={toggleSidebar}>
          <HiOutlineMenuAlt3 />
        </div>
        <div className="header__actions__logo">
          <img src={HeaderLogo} alt="Logo" />
        </div>
        <div className="header__actions__welcome">Welcome</div>
      </div>
      <div className="header__user">
        <div
          className="header__user__down"
          onClick={handleMenuOpen} // Abre o menu ao clicar
        >
          <div className="header__user__icon">
            <HiOutlineUserCircle />
          </div>
          <p className="header__user__name">{userName}</p>
          <HiChevronDown />
        </div>

        {/* Dropdown de Logout */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)} // Controla se o menu está aberto
          onClose={handleMenuClose} // Fecha o menu ao clicar fora
          PaperProps={{
            style: {
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            },
          }}
        >
          <MenuItem onClick={userinfo} style={{ fontWeight: 600 }}>
            Your Information
          </MenuItem>
          <MenuItem onClick={changepassword} style={{ fontWeight: 600 }}>
            Change Password
          </MenuItem>
          <MenuItem onClick={logout} style={{ fontWeight: 600 }}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
