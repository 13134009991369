import { useContext, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from "@mui/material";
import Toast from "../shared/toast";
import { authService } from "../services/authService";
import BannerBackground from "../assets/login-background.png";
import Logo from "../assets/login-logo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FooterBar from "../shared/footer";
import { useNavigate, NavLink } from "react-router-dom";
import { apiService } from "../services/apiService";
import { validator } from "../shared/validator";
import urlHomeBase from "../services/urlBase";
import "./LoginPage.scss";
import { SidebarContext } from '../context/SidebarContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setMsgToast] = useState('');
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState('');
  const { refreshSidebarData } = useContext(SidebarContext);
  const [loading, setLoading] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true); 
    setEmailError(validator.validateEmail(email));

    if (!password) {
      setPasswordError('* Please enter your password');
    } else {
      setPasswordError('');
    }

    if (email && password) {
      const userData = { email, password };

      try {
        const apiLogin = await authService.login(userData, rememberMe);
        setUserName(apiLogin.data.firstName + ' ' + apiLogin.data.lastName);
        if (apiLogin?.data?.token) {
          const userRole = await authService.getRoleByNumber(apiLogin?.data?.role);
          const userbaseUrl = urlHomeBase[userRole];

          // Termos removidos provisoriamente por ordem da Rafa
          //if (apiLogin.data.agreementTerms === null && (apiLogin.data.role === 2 | apiLogin.data.role === 6)) {
          //  setOpenTermsDialog(true);
          //  return;
          //}

          await refreshSidebarData();
          navigate(userbaseUrl);
        }
      } catch (error) {
        await authService.logOut();
        setMsgToast(error.response.data == null ? error.message : error.response.data.message);
        setError(true);
        setOpenToast(true);
      }
      setLoading(false);
    }
  };

  const handleForgotPasswordClick = async () => {
    setEmailError(validator.validateEmail(email));

    if (email) {
      try {
        await apiService.mailresetpassword(email);
        setError(false);
        setMsgToast('You will receive an email with<br/>a new password.');
        setOpenToast(true);
      } catch (error) {
        await authService.logOut();
        setMsgToast(error.response.data);
        setError(true);
        setOpenToast(true);
      }
    }
  };

  const handleCloseToast = async () => {
    setOpenToast(false);
    if (!error) {
      // Adiciona um atraso de 2 segundos antes de redirecionar
      setTimeout(() => {
      }, 2000); // 2000ms = 2 segundos
    }
    if (await authService.getEmail()) {
      navigate('/home');
    } else {
      //setEmail('');
      setPassword('');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleAcceptTerms = async () => {
    await apiService.userAcceptTerms();
    setAcceptedTerms(true);
    const user = await authService.getUserData();
    const userbaseUrl = urlHomeBase[user.role];
    navigate(userbaseUrl);
    setOpenTermsDialog(false);
  };

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);
  };

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  return (
    <>
      <div
        className="login"
        style={{ backgroundImage: `url(${BannerBackground})` }}
      >
        <div className="login__container">
          <div className="login__card">
            <div className="login__logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="login__header">
              <h1 className="login__title">Sign-in</h1>
              <p className="login__subtitle">
                Log in with your login and password
              </p>
            </div>
            <div>
              <div>
                <form onSubmit={submitForm} className="login__form">
                  <div>
                    <TextField
                      fullWidth
                      label="Login"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      margin="normal"
                      variant="filled"
                      type="email"
                    />
                    {emailError && (
                      <small className="text-danger">{emailError}</small>
                    )}
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="filled"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passwordError && (
                      <small className="text-danger">{passwordError}</small>
                    )}
                  </div>
                  <div>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                            color="primary"
                          />
                        }
                        label="Remember Me"
                      />
                      <NavLink to="#" onClick={handleForgotPasswordClick} style={{ textDecoration: 'none' }}>
                        <span style={{ color: '#1976d2' }}>Forgot Password?</span>
                      </NavLink>
                    </Stack>
                  </div>
                  <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                  {loading ? (
                    <CircularProgress /> // Mostra o spinner enquanto carrega
                  ) : (
                    <Button
                      className="login__submit"
                      variant="contained"
                      type="submit"
                    >
                      Enter
                    </Button>)}
                    </Box>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="login__footer">
          <p className="text-item login__footer__title">
            Myomar Molecular Inc.
          </p>
          <p className="text-item">+1 902-483-6644</p>
          <p className="text-item">support@myomarmolecular.com</p>
        </div>
      </div>
      <Dialog open={openTermsDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpenTermsDialog(false);
          }
        }} maxWidth='lg'>
        <DialogContent>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', border: '1px solid #ccc', borderTop: '2px solid #ccc', }}>
            <Typography variant="h5" sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
              BY CLICKING THE "AGREE" BUTTON BELOW, YOU (A) AGREE TO PARTICIPATE IN THE EARLY ADOPTER PROGRAM;
              (B) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT;
              (C) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT;
              (D) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS;
              (E) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND MYOMAR’S GENERAL TERMS AND CONDITIONS (“TERMS AND CONDITIONS”),
              WHICH TERMS AND CONDITIONS ARE INCORPORATED INTO THIS AGREEMENT BY REFERENCE; AND
              (F) ACCEPT MYOMAR’S TERMS AND CONDITIONS AND AGREE THAT YOU ARE LEGALLY BOUND THEREBY.
              EARLY ADOPTER AGREEMENT
            </Typography>
            <p>
              Myomar Molecuar Inc. (“Myomar”) has established an early adopter program relating to the use of Myomar’s urinalysis test kits (inclusive of a sample container and instructions, the “Test Kits”) and other products (together with the Test Kits, the "Products"), software applications ("Software"), and associated services ("Services") used for monitoring muscle metabolism through biomarkers in urine samples (the “Early Adopter Program”). This Early Adopter Agreement (the "Agreement") is made between you and Myomar, regarding your participation in the Early Adopter Program.
            </p><p>1.	PRICING AND FEES
              Myomar will provide you with a reasonable supply of unused Test Kits upon commencement of the Early Adopter Program, and thereafter upon request, at no cost. Each Test Kit will be available to you to purchase at a cost of $92 per Test Kit, which fee shall crystallize when you Myomar collects a Test Kit for the purpose of conducting our proprietary analysis on the collected sample. You will receive access to Myomar’s Software at no monthly cost during the Early Adopter Term. This pricing model is offered to you on the expectation that you will submit orders on a weekly basis during the Early Adopter Term.
              Myomar may, from time to time, provide advice or offer guidance to you regarding pricing for the Products (including the Test Kits). You are not be obligated to accept such advice or guidance and shall retain the sole right to determine the prices at which you offer the Products, Software or Services. Whenever Myomar recommends a suggested retail price, such recommendations are based on Myomar’s experience, considering various factors that contribute to appropriate pricing, but these recommendations are not binding on you. No advice or guidance shall be construed to impose any obligation on you to charge fixed, minimum, or maximum prices for any Products, Software or Services offered. The current suggested retail price for each Test Kit is $150.00. You agree that you will not retail the Test Kits for a price in excess of $150.00.
              All prices mentioned in this Agreement are in Canadian dollars (CAD) and are exclusive of any applicable sales taxes, unless otherwise stated.
              Myomar will issue invoices to you for the purchase of Test Kits and any applicable fees as each Test Kit is activated, where “activated” means the moment when the Test Kit is registered in Myomar’s system as being assigned to a specific patient or provider and is marked as ready for use or processing. Invoices will include the number of Test Kits activated, price per unit, activation date, applicable taxes, and any other charges incurred during the billing period.
              Invoices will be delivered electronically via email to the billing contact provided during enrollment in the Early Adopter Program, or through Myomar’s app. You are responsible for ensuring that your contact information is up-to-date.
              Payment for each invoice is due within thirty (30) days from the invoice date unless otherwise specified in the invoice. Payments may be made via direct deposit to Myomar’s account or another method agreed-upon in writing by you and Myomar. Failure to pay within the specified time may result in suspension of access to the Products, Software, and Services.
              All overdue payments not received by Myomar within the above noted period will incur a late fee of two percent (2%) of the outstanding balance per month. In the event of continued non-payment, Myomar reserves the right to terminate your participation in the Early Adopter Program.
              If you dispute any charges on an invoice, you must notify Myomar within ten (10) business days of receipt of the invoice, providing all relevant information regarding the dispute. Failure to do so will result in the invoice being deemed accepted.
              All payments made under this Agreement are non-refundable unless otherwise stated. In the event of a dispute or early termination, any potential refund entitlements will be handled on a case-by-case basis in Myomar’s sole discretion.
            </p><p>2.	FEEDBACK REQUIREMENTS
              As part of the Early Adopter Program, you agree to provide ongoing suggestions, comments and feedback to Myomar on the performance, usability, pricing and effectiveness of the Products, Software, and Services (the “Feedback”). This Feedback is essential for refining Myomar’s offerings and may cover areas such as:
            </p><p>(a)	the overall experience with the Products, Software and Services;
            </p><p>(b)	ease of ordering and processing Test Kits;
            </p><p>(c)	market feedback on pricing;
            </p><p>(d)	practitioner feedback on the testing results and how they implement it into their advice to end users; and
            </p><p>(e)	suggestions for improvements, technical issues, and bug reports.
              Feedback is welcome at any time and can be provided on an ad hoc basis.  Myomar asks that you endeavour to provide Feedback no less than once a month during the Early Adopter Term.  Additionally, you agree to participate in surveys, interviews, or other evaluation methods as part of the Feedback process. Myomar is not obligated to incorporate any feedback into its Products, Software, or Services.
            </p><p>3.	INTELLECTUAL PROPERTY
              Myomar retains ownership of all Products, Software, Services, and any Feedback, as well as any derivatives thereof, whether or not influenced by your suggestions or your Feedback. You acknowledge that your use of Myomar’s Products, Software, and Services is subject to the intellectual property provisions outlined in Myomar’s Terms and Conditions. You further agree that all Data (as defined below) collected and all Feedback provided by you as part of this Agreement are the property of Myomar, are deemed to be Confidential Information and are subject to all intellectual property provisions, including restrictions on use, ownership, and licensing, outlined in Myomar’s Terms and Conditions.
              By participating in the Program, you agree to abide by all applicable intellectual property terms, including restrictions on use, ownership rights, licensing and any other related provisions contained in Myomar’s Terms and Conditions. Please refer to Myomar’s Terms and Conditions for the complete terms governing intellectual property rights.
              You hereby grant Myomar a non-exclusive, non-transferable, royalty-free, perpetual license that shall survive the termination or expiration of this Agreement to use your name and logo on its promotional materials, solely for the purpose of identifying you as a customer and user of the Products, Software and Services.
            </p><p>4.	DATA PRIVACY AND CONSENT
              All information made available to or collected by Myomar in connection with this Agreement, or derived from the Products, Software or Services (the “Data”) shall be treated in accordance with the Terms and Conditions and Myomar’s Privacy Policy.
            </p><p>5.	TERM AND TERMINATION
              The Program will last twenty (20) weeks, commencing from the date of your acceptance of this Agreement(the “Early Adopter Term”).
              Either party may terminate this Agreement during the Early Adopter Term with thirty (30) days' written notice to the other party. Myomar may terminate this Agreement immediately in the event of a material breach of this Agreement or the Terms and Conditions. Upon early termination of this Agreement:
            </p><p>(a)	any and all rights and licenses granted to you under this Agreement or the Terms and Conditions shall immediately terminate, except that access credentials to the Software shall remain available on a read only basis for 60 days;
            </p><p>(b)	any Test Kits submitted prior to the end of the notice period shall be analyzed by Myomar with results submitted through the Software in the ordinary course.
            </p><p>(c)	you shall immediately cease using the Products, Services or Software and allow Myomar to collect all unused Products at a mutually convenient time at Myomar’s expense;
            </p><p>(d)	you will return to Myomar any documentation, and all other tangible items in your possession or under your control that are proprietary to Myomar or that contain Confidential Information; and
            </p><p>(e)	any fees that have already been paid by you to Myomar shall be non-refundable.
              6.	COVENANTS AND OBLIGATIONS
              During the Early Adopter Term, you covenant to and shall be responsible for:
            </p><p>(a)	cooperating with Myomar to ensure that the Products, Software, and Services operate as efficiently as possible. This includes ensuring that your staff and personnel using the Products, Software, or Services are adequately trained on the use of the Products, Software, or Services;
            </p><p>(b)	notifying Myomar of any issues, bugs, or operational difficulties promptly and assisting Myomar in diagnosing and resolving such issues;
            </p><p>(c)	providing detailed Feedback and cooperating with Myomar on the development and enhancement of the Products, Software, and Services. This may include participating in testing, providing insights on performance, and recommending improvements to functionality and usability, to help Myomar refine its offerings; and
            </p><p>(d)	using the Products, Software, and Services only for their intended purposes in a lawful and ethical manner and refraining from engaging in any activities that could harm the reputation, intellectual property, or interests of Myomar or its partners.
              Failure to comply with these covenants and obligations may result in termination of this Agreement and revocation of any rights granted to you under the Early Adopter program, at Myomar’s sole discretion.
              During the Early Adopter Term, Myomar covenants and agrees that it:
            </p><p>(a)	has, and will maintain, all required permits, licenses, consents and regulatory approvals required in connection with the Program, including a Medical Device Establishment License granted by Health Canada;
            </p><p>(b)	will co-operate with you to ensure that the Products, Software, and Services operate as efficiently as possible; and
            </p><p>(c)	will respond expeditiously to any reports of issues, bugs, or operational difficulties.
            </p><p>7.	CONFIDENTIALITY
              The parties hereto acknowledge that, as a result of each party carrying out its obligations under this Agreement and as part of the Program, from time to time one party (the "Receiving Party") may gain access to or become aware of information of the other party (the "Disclosing Party") which is marked as "proprietary" or "confidential" or which would, under the circumstances, be understood by a reasonable person to be proprietary and non-public, which shall include the terms and conditions of this Agreement ("Confidential Information"). The Receiving Party shall retain such Confidential Information in confidence and shall not disclose it to any third party without the Disclosing Party’s written consent. Each Party shall use at least the same procedures and degree of care which it uses to protect its own Confidential Information of like importance, and in no event less than reasonable care.
              Notwithstanding the foregoing, Confidential Information will not include information to the extent that such information
            </p><p>(a) was already known by the Receiving Party without an obligation of confidentiality at the time of disclosure hereunder;
            </p><p>(b) was generally available to the public at the time of its disclosure to the Receiving Party hereunder;
            </p><p>(c) became generally available to the public after its disclosure other than through an act or omission of the Receiving Party in breach of this Agreement;
            </p><p>(d) was subsequently lawfully and independently disclosed to the Receiving Party by a person other than Disclosing Party;
            </p><p>(e) was independently developed by the Receiving Party; or
            </p><p>(f) is disclosed pursuant to the order or requirement of a court, administrative agency, or other governmental body, provided, however, that the Receiving Party shall provide prompt notice thereof to enable the Disclosing Party to seek a protective order or otherwise prevent such disclosure.
            </p><p>8.	NO WARRANTIES
              Disclaimer
              THE DATA AND RESULTS GENERATED BY THE PRODUCTS, SOFTWARE, SERVICES AND PARTICIPANT DATA ARE SUBJECT TO ERROR AND MYOMAR ENSURES THAT THE ERRORS ARE MINIMAL BY ADHERING TO QUALITY ASSURANCE PROCEDURES FOR SOFTWARE AND PRODUCTS, HOWEVER THE TEST SHOULD NOT BE USED AS THE SOLE BASIS FOR TREATMENT OR DECISION MAKING. ANY DISCREPANCIES OR POTENTIAL INACCURACIES SHOULD BE FURTHER EVALUATED THROUGH ADDITIONAL CLINICAL METHODS AT THE DISCRETION AND JUDGEMENT OF THE PRACTITIONER. PRACTITIONERS ARE RESPONSIBLE FOR USING THEIR PROFESSIONAL JUDGMENT WHEN INTERPRETING DATA AND REPORTS GENERATED BY MYOMAR. MYOMAR ASSUMES NO LIABILITY FOR RELIANCE ON OR USE OF THE DATA OR RESULTS GENERATED THROUGH ITS PRODUCTS, SOFTWARE, OR SERVICES. A PRIMARY PURPOSE OF THIS AGREEMENT IS TO OBTAIN FEEDBACK ON PERFORMANCE AND THE IDENTIFICATION OF ERRORS.
              Products, Software, and Services Provided "As-Is"
              EXCEPT AS REQUIRED BY APPLICABLE LAW, AND SUBJECT TO ANY LIMITED WARRANTIES EXPRESSLY PROVIDED IN THE APPLICABLE MYOMAR PRODUCT LABELING, OUR PRODUCTS, SOFTWARE, AND SERVICES ARE PROVIDED "AS-IS" AND ON AN "AS AVAILABLE" BASIS. YOU ACKNOWLEDGE AND AGREE THAT YOU USE OUR PRODUCTS, SOFTWARE, AND SERVICES AT YOUR OWN RISK.
              Disclaimer of Implied Warranties
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MYOMAR DISCLAIMS ALL IMPLIED WARRANTIES OR CONDITIONS, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, NON-INFRINGEMENT, OPERATION OR COMPLIANCE WITH DOCUMENTATION. MYOMAR FURTHER DISCLAIMS ANY PROVISIONS IMPLIED BY ANY STATUTORY OR COMMON LAW PROVISIONS, TO THE EXTENT SUCH DISCLAIMERS ARE PERMITTED BY APPLICABLE LAW.
              No Warranty of Uninterrupted or Error-Free Services
              MYOMAR DOES NOT GUARANTEE THAT ITS PRODUCTS, SOFTWARE, OR SERVICES, INCLUDING ANY DATA OR REPORTS PROVIDED, WILL MEET YOUR REQUIREMENTS, OR THAT ACCESS TO THE PRODUCTS, SOFTWARE, OR SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. MYOMAR DOES NOT WARRANT THAT ALL ERRORS WILL BE CORRECTED, OR THAT THE PRODUCTS, SOFTWARE OR SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MYOMAR TAKES CARE TO PROPER MAINTAIN SOFTWARE AND SERVICES TO ENSURE PROTECTION FROM VIRUSES AND HARMFUL COMPONENTS USING TOKEN ACCESS BASED CONTROL, CRYPTOGRAPHY FOR PASSWORDS, SEPARATED BACK AND FRONT END CONTEXTS. ADDITIONALLY, MYOMAR OBTAINED SSL CERTIFICATES FOR WEBSITE ACCESS AND ENSURE THAT DATA IS NOT ACCESSIBLE THROUGH OPEN DATA TRAFFIC.
              No Warranty on Results
              ALL TEST RESULTS ARE PROVIDED SOLELY FOR INFORMATIONAL PURPOSES AND ARE INTENDED TO SUPPLEMENT, NOT REPLACE, THE PROFESSIONAL JUDGMENT OF THE PRACTITIONER TO WHOM THE TEST RESULTS ARE PROVIDED. THE DATA AND RESULTS GENERATED BY THE PRODUCTS, SOFTWARE, SERVICES AND PARTICIPANT DATA ARE SUBJECT TO ERROR AND MYOMAR ENSURES THAT THE ERRORS ARE MINIMAL BY ADHERING TO QUALITY ASSURANCE PROCEDURES FOR SOFTWARE AND PRODUCTS, HOWEVER THE TEST SHOULD NOT BE USED AS THE SOLE BASIS FOR HEALTHCARE DIAGNOSIS, TREATMENT OR DECISION MAKING. ANY DISCREPANCIES OR POTENTIAL INACCURACIES SHOULD BE FURTHER EVALUATED THROUGH ADDITIONAL CLINICAL METHODS AT THE DISCRETION AND JUDGEMENT OF THE PRACTITIONER. PRACTITIONERS ARE RESPONSIBLE FOR USING THEIR PROFESSIONAL JUDGMENT WHEN INTERPRETING DATA AND REPORTS GENERATED BY MYOMAR. MYOMAR ASSUMES NO LIABILITY FOR RELIANCE ON OR USE OF THE DATA OR RESULTS GENERATED THROUGH ITS PRODUCTS, SOFTWARE, OR SERVICES.
              No Warranty on Third-Party Devices or Services
              MYOMAR DOES NOT WARRANT THE PERFORMANCE OR RELIABILITY OF ANY THIRD-PARTY DEVICES, SOFTWARE, SERVICES, OR DATA THAT YOU MAY USE IN CONJUNCTION WITH OUR PRODUCTS, SOFTWARE, OR SERVICES, WHETHER OR NOT SUCH THIRD-PARTY ITEMS ARE DESCRIBED IN, OR COMPATIBLE WITH, OUR PRODUCTS, SOFTWARE, OR SERVICES.
              Exclusive Remedy
              EXCEPT AS PROVIDED UNDER ANY LIMITED WARRANTY AS REQUIRED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE PRODUCTS, SOFTWARE, OR SERVICES IS TO DISCONTINUE USING THEM.
            </p><p>9.	LIMITATION OF LIABILITY AND YOUR RESPONSIBILITY
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MYOMAR AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, LICENSORS, SUPPLIERS, AND THIRD-PARTY CONTRACTORS (COLLECTIVELY, THE "MYOMAR PARTIES") SHALL NOT BE LIABLE FOR ANY DIRECT OR INDIRECT DAMAGES ARISING OUT OF OR RELATED TO THE USE OR INABILITY TO USE MYOMAR’S PRODUCTS, SOFTWARE, OR SERVICES.
              EXCEPT TO THE EXTENT PROHIBITED UNDER APPLICABLE LAW, NONE OF THE MYOMAR PARTIES SHALL BE LIABLE FOR ANY CONSEQUENTIAL, UNFORESEEABLE, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES, OR DAMAGES RESULTING FROM LOSS OF DATA OR BUSINESS INTERRUPTION, WHETHER ARISING FROM CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF MYOMAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, MYOMAR’S TOTAL LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO ITS PRODUCTS, SOFTWARE, OR SERVICES SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100).
              MYOMAR SHALL NOT BE LIABLE FOR THE ACTIONS OR OMISSIONS OF ANY THIRD PARTY, INCLUDING ANY PRACTITIONER, CLINIC, PARTICIPANT, OR OTHER USER OF THE PRODUCTS, SOFTWARE OR SERVICES.
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS AND DISCLAIMERS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. TO THE EXTENT MYOMAR MAY NOT DISCLAIM ANY IMPLIED WARRANTY OR LIMIT ITS LIABILITY, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF MYOMAR’S LIABILITY SHALL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW.
              NOTHING IN THIS AGREEMENT IS INTENDED TO EXCLUDE LIABILITY WHERE SUCH LIABILITY IS MANDATORY UNDER APPLICABLE LAW.
            </p><p>10.	GOVERNING LAW AND CHOICE OF FORUM
              This Agreement shall be governed by and interpreted in accordance with the laws of the Province of Nova Scotia, without regard to the conflicts of law rules thereof. Any claim or dispute arising in connection with this Agreement shall be resolved in the Courts situated within the Province of Nova Scotia. The Parties hereby attorn to the non-exclusive jurisdiction and venue of such courts and waive any objections to the jurisdiction or venue of such courts.

            </p>
            <br />

          </Paper>
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleAcceptTerms} color="primary"
            sx={{
              width: "150px",
              backgroundColor: "rgba(197, 220, 255, 1)",
              color: "#000000",
              fontWeight: "bold",
              fontFamily: "Roboto",
              borderColor: "rgba(197, 220, 255, 1)"
            }}
          >
            Accept
          </Button>

          <Button
            variant="contained"
            onClick={handleCloseTermsDialog}
            sx={{
              width: "150px",
              backgroundColor: "rgba(197, 220, 255, 1)",
              color: "#000000",
              fontWeight: "bold",
              fontFamily: "Roboto",
              borderColor: "rgba(197, 220, 255, 1)",
              marginRight: "40px"
            }}
          >
            Decline
          </Button>
        </DialogActions>




      </Dialog>

      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        message={msgToast}
        error={error}
      />
    </>
  );
}

export default LoginPage;
