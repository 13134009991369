import React, { useEffect, useState } from "react";
import SideBar from "../shared/sideBar";
import Header from "../shared/header";
import NavInfo from "../shared/NavInfo";
import { Box, CssBaseline } from "@mui/material";
import UsersCheck from "../assets/icons/Users/users-check.svg";
import UserUp2 from "../assets/icons/Users/user-up-02.svg";
import UsersMinus from "../assets/icons/Users/users-minus.svg"; // Ícone já existe
import Glasses2 from "../assets/icons/Education/glasses-02.svg";
import CheckDone2 from "../assets/icons/General/check-done-02.svg";
import ClockSnooze from "../assets/icons/Time/clock-snooze.svg";
import "./Dashboard.scss";
import { apiService } from "../services/apiService";

import CheckCircleIcon from "../assets/icons/General/check-circle.svg"; // Para Tests Finished
import MicroscopeIcon from "../assets/icons/Education/microscope.svg"; // Para Active Tests
import ReportIcon from "../assets/icons/Chart/bar-chart-01.svg"; // Para Tests To Report



const Dashboard = () => {
  const [counters, setCounters] = useState({
    partners: 0,
    clients: 0,
    activeTests: 0,
    testsToDropOff: 0,
    testsToReport: 0,
    testsFinished: 0,
  });

  useEffect(() => {
    const fetchCounters = async () => {
      try {
        const [
          partners,
          clients,
          activeTests,
          testsToDropOff,
          testsToReport,
          testsFinished,
        ] = await Promise.all([
          apiService.getActivePartnersCount(),
          apiService.getActiveClientsCount(),
          apiService.getActiveTestsCount(),
          apiService.getTestsToDropOffCount(),
          apiService.getTestsToReportCount(),
          apiService.getFinishedTestsCount(),
        ]);

        setCounters({
          partners: partners.data,
          clients: clients.data,
          activeTests: activeTests.data,
          testsToDropOff: testsToDropOff.data,
          testsToReport: testsToReport.data,
          testsFinished: testsFinished.data,
        });
      } catch (error) {
        console.error("Error fetching counters", error);
      }
    };

    fetchCounters();
  }, []);

  const cards = [
    { icon: UsersCheck, title: "Partners", value: counters.partners },
    { icon: UserUp2, title: "Clients", value: counters.clients },
    { icon: MicroscopeIcon, title: "Active Tests", value: counters.activeTests },
    { icon: ClockSnooze, title: "Tests To DropOff", value: counters.testsToDropOff },
    { icon: ReportIcon, title: "Tests To Report", value: counters.testsToReport },
    { icon: CheckCircleIcon , title: "Tests Finished", value: counters.testsFinished },
  ];

  return (
    <>
      <Header />
      <div className="bgColor">
        <Box sx={{ display: "flex" }}>
          <SideBar />
          <CssBaseline />
          <Box sx={{ flexGrow: 1, overflow: "visible", height: "100vh", p: 3 }}>
            <NavInfo
              currentLinkText=""
              title="Home / Dashboard"
              canShowButton={false}
            />
            <div className="dashboard__cards">
              {cards.map((card) => (
                <div className="dashboard__card" key={card.title}>
                  <img src={card.icon} className="dashboard__card__icon" />
                  <p className="dashboard__card__title">{card.title}</p>
                  <p className="dashboard__card__value">{card.value}</p>
                </div>
              ))}
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Dashboard;
